import React from "react"
import styled from "styled-components"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import { Section, BreadCrumb, MarkdownContent } from "../components/Section"
import RightLineArrowIcon from "../components/Icons/RightLineArrowIcon"
import CallDesignBuilding from "../components/CallDesignBuilding"
import PrimaryButton from "../components/Button/PrimaryButton"
import BreakpointUp from "../components/Media/BreakpointUp"

const BackgroundImage = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
`

const TopHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  margin: 0 -10px;
`
const TopHeaderFigure = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  padding: 0 10px;
  ${BreakpointUp.lg`      
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  `}
  figure {
    position: relative;
    margin: 0;
  }
  & a {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
`
const TopHeaderBody = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.lg`      
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  `}
`
const TopHeaderTitle = styled.h1`
  display: block;
  margin: 0;
  color: #fff;
`
const ColorPlanner = ({ data, location }) => (
  <Layout location={location} >
    <Seo title={data.contentfulColorPlannerPage.metaTitle} description={data.contentfulColorPlannerPage.metaDescription.metaDescription} />

    <Section
      pt="215px"
      pb="150px"
      xpt="120px"
      xpb="90px"
      bgColor="#FAFAFA"
      bdrBottom="#707070"
    >
      <BackgroundImage>
        <GatsbyImage
          image={data.contentfulColorPlannerPage.heroImage.gatsbyImageData}
          alt={data.contentfulColorPlannerPage.heroImage.title}
        />
      </BackgroundImage>
      <div className="container">
        <TopHeader>
          <TopHeaderBody>
            <BreadCrumb className="white top-0" mb="10px">
              <Link to="/">Home / </Link>
              <span>{data.contentfulColorPlannerPage.pageName}</span>
            </BreadCrumb>
            <TopHeaderTitle>3D ESTIMATOR & Color Planner</TopHeaderTitle>
          </TopHeaderBody>
          <TopHeaderFigure>
            <figure>
              <StaticImage
                src="../images/color-carport.jpg"
                alt="color-carport"
              />
            </figure>
            <a
              href="https://coasttocoastcarports.sensei3d.com/"
              target="_blank"
              rel="noreferrer"
            >
              <PrimaryButton
                icon={<RightLineArrowIcon />}
                textBefore="Design Your Building"
              />
            </a>
          </TopHeaderFigure>
        </TopHeader>
      </div>
    </Section>
    <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
      <div className="container mw-950">
        <MarkdownContent>
          <div
            dangerouslySetInnerHTML={{
              __html:
                data.contentfulColorPlannerPage.content.childMarkdownRemark
                  .html,
            }}
          />
        </MarkdownContent>
      </div>
    </Section>
    <CallDesignBuilding />
  </Layout>
)

export default ColorPlanner

export const pageQuery = graphql`
  query ColorPlannerQuery {
    contentfulColorPlannerPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      pageName
      heroImage {
        title
        gatsbyImageData
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
